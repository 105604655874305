* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
a{
    color: inherit;
    text-decoration: none;
}
.wrapper{
    position: relative;
    margin: 0px auto;
    width: 1200px;
}
.header{
    margin-bottom: 20px;
}